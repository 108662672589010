<template>
    <div class="register-success">
        <div class="flex-box">
            <img src="@/assets/images/logo.png" class="logo" alt="" v-if="types == 1">
            <img src="@/assets/images/chenggong.png">
            <p>{{tips}}</p>
        </div>
        <!-- 公众号二维码 -->
        <we-chat-public-q-r-code></we-chat-public-q-r-code>
        <!--
		<div class="nav">
			<router-link to='/' replace class="link">进入首页</router-link>
			<router-link to='/tanlent' replace class="link gray">掌柜工作台</router-link>
		</div>
        -->
    </div>
</template>

<script>
    import {
        getUser
    } from "@/http/api";
    import WeChatPublicQRCode from '@/components/WeChatPublicQRCode'
    export default {
        name: '',
        components: {
            WeChatPublicQRCode
        },
        props: {},
        data() {
            return {
                tips: '',
                types: this.$route.query.types
            }
        }, 
        mounted() {
			this.userToken = this.$LStorage.getItem("userToken").user_token;
            let types = this.types;
            switch (types) {
                case '1':
                    this.tips = '您之前受邀注册过，已经是掌柜了';
                    return
                case '2':
                    this.tips = '恭喜您成功注册为掌柜';
                    return
            }
			this.getUserInfo()
        },
        methods: {
            // 获取用户个人信息
            async getUserInfo() {
                const res = await getUser({
                    data: {
                        user_token: this.userToken
                    }
                });
                if (res.code == 200) {
                    this.$LStorage.setItem("userInfo", res.data);
                }
            },
		}
    }
</script>

<style scoped lang="scss">
    .register-success {
        background: #f6f6f6;
        min-height: 100vh;

        .flex-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            height: max-content;
            background: #fff;
            padding: 0.8rem 0;
            margin-bottom: 0.2rem;

            img {
                width: 40vw;
            }

            .logo {
                width: 4rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 0.28rem;
                line-height: 3;
            }
        }

        .qr-code-box {
            margin: 0 0.24rem;
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 50px 0;
            width: 70vw;
            margin: 0 auto;

            .link {
                border: 1px solid #e40011;
                color: #e40011;
                font-size: 0.32rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32vw;
                height: 10vw;
            }

            .gray {
                color: #8B8B8B;
                border-color: #DCDCDC;
            }
        }
    }
</style>
